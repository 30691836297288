<template>
    <div class="course">
        <van-nav-bar  class="top" fixed left-arrow @click-left="onBack">
            <template #title>
                <span class="topCenter" @click="handleClickProject" >{{groupName}}<van-icon color="#333333" name="play" /></span>
            </template>
            <template #right>
                <van-grid :column-num="1" class="topRight">
                    <van-grid-item icon="comment-o" text="客服" @click="mySchool" />
                </van-grid>
            </template>
        </van-nav-bar>
        <!-- <van-row class="top">
            <van-col span="4">
            </van-col>
            <van-col span="16" class="top_left">
                <van-button color="#ffffff" @click="handleClickProject" >{{groupName}}<van-icon name="play" /></van-button>
            </van-col>

           <van-col span="4" class="top_right">
               <van-grid :column-num="1">
                   <van-grid-item icon="comment-o" text="客服" @click="mySchool" />
               </van-grid>
           </van-col>
        </van-row> -->
        <!-- <van-search v-model="SearchValue"  shape="round" placeholder="请输入搜索关键词1" disabled
          @click="handleClickSearch"
         /> -->
        <transition name="van-slide-right" mode="out-in">
            <router-view></router-view>
        </transition>

        <!--        <div class="banner">-->
        <!--            <van-swipe class="my-swipe" :autoplay="3000" :height="120" indicator-color="#1989fa">-->
        <!--                <van-swipe-item v-for="item in banner" :key="item.id">-->
        <!--                    <img :src="item.url" width="100%" style="display: block;" alt />-->
        <!--                </van-swipe-item>-->
        <!--            </van-swipe>-->
        <!--        </div>-->
        <!-- <div v-if="LiveBroadcastList.length > 0"> -->
        <div class="live_column"  v-if="LiveBroadcastList.length > 0">
            <div class="article" v-for="item in LiveBroadcastList">
                <div class="arc_mid">
                    <van-cell>
                        <template #title @click="showDetail(item.id,item.roomid)">
                            <!-- <span class="custom-title">{{item.name}}</span> -->
                            <van-image fit="cover" radius=".6rem" width="100%" height="auto" :src="item.share_img" >
                                <van-tag type="danger" size="large" class="live_flag" v-if="item.type == 1">购课直播</van-tag>
                                <van-tag type="success" size="large" class="live_flag" v-else>免费直播</van-tag>
                                <span class="datetime" style="">{{item.start_time}}-{{item.end_time}}</span>
                            </van-image>
                            <div v-if="item.live_status == 102">
                                <van-button round icon="play" size="small" class="play_buy play_green" @click="showDetail(item.id,item.roomid)">{{item.subscribeInfo?'进入直播':'开播提醒'}}</van-button>
                            </div>
                            <div v-else>
                                <van-button round icon="play" size="small" class="play_buy play_green" @click="showDetail(item.id,item.roomid)">进入直播</van-button>
                            </div>
                        </template>
                        <template #label>
                            <span class="flag-title">{{item.name}}</span>
                            <!-- <span class="flag-date"></span> -->
                            <!-- <span class="flag-read">讲师:{{item.anchor_name}}</span> -->
                        </template>
                    </van-cell>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <div v-else>
            <van-empty
                    description="暂无直播"
                    image="http://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                    image-size="24rem">
                <!--        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>-->
            </van-empty>
        </div>

    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    // import axios from "axios"


    import {onShareUrl, liveBroadcast ,liveBroadcast2 ,liveReservationStatus ,appletUrl} from '@/request/api'
    import {Toast} from "vant";
    import wx from "weixin-js-sdk";

    export default {
        name: 'liveBroadcastList',
        metaInfo() {
            return {
                title: this.title, //设置页面title
            }
        },
        // metaInfo: {
        //     title: '一通教育网校选课中心', //设置页面title
        //     meta: [{                 //设置页面meta
        //         name: 'keyWords',
        //         content: '关键词'
        //       },
        //       {
        //         name: "description",
        //         content: "描述",
        //       },
        //     ]
        // },
        data() {
            return {
                title: this.getBranchCompany(),
                SearchValue: "",
                active: '',
                item: '',
                //轮播图数组
                banner: [],
                show: false,

                LiveBroadcastList:[],
                groupName: (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择'),
            };
        },
        methods: {
            onBack() {
                // history.back();
                this.$router.push('/index');
            },
            mySchool(){
                this.$router.push('/MySchool')
            },
            handleClickSearch() {
                this.$router.push('/course/searchPopup')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },
            handleClickProject() {
                this.$router.push({
                    name: 'Project',
                    query: {
                        redirect: (this.$route.name)
                    }
                })
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("show", true)
                // console.log("搜索按钮被点击");
            },
            handleClickCourseDetail(id) {
                if(this.getToken()) {
                    this.$router.push({
                        name: 'CourseDetail',
                        params:{
                            id
                        },
                        query:{
                            id
                        }
                    })
                } else {
                    Toast.fail('请先登录');
                    this.$router.push({
                        name: 'User',
                        query: {redirect: this.$route.name,isShowModal:true}
                    })
                }

                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },
            handleClickShopingCart() {
                // this.$router.push('/cart')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                Toast('暂未开放');
            },
            getData() {
                let that = this;
                let token = that.getToken();
                if(token) {
                    liveBroadcast()
                        .then(res => {
                            if(res.data.code == 1) {
                                that.LiveBroadcastList = res.data.data
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                } else {
                    liveBroadcast2({groupid:that.getGroupId()})
                        .then(res => {
                            if(res.data.code == 1) {
                                that.LiveBroadcastList = res.data.data
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                }
            },
            onWxConfig() {
                let that = this;
                let branchId = that.getBranch();
                // alert(location.href.split('#')[0]);
                // alert(window.location.origin);
                onShareUrl({url: location.href.split('#')[0],branchId})
                    .then(res => {
                        if (res.data.code == 1) {
                            wx.config({
                                debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                                timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                                nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                                signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                                jsApiList: [
                                    'checkJsApi',
                                    // 'onMenuShareQQ',
                                    // 'onMenuShareWeibo',
                                    // 'onMenuShareQZone',
                                    'updateAppMessageShareData',
                                    'updateTimelineShareData',
                                    // 'onMenuShareTimeline',
                                    // 'onMenuShareAppMessage',
                                    // 'wx-open-launch-weapp'
                                ],
                                // openTagList: ['wx-open-launch-weapp']
                            })
                            wx.checkJsApi({
                                jsApiList: ['chooseImage', 'updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                                success: function(res) {
                                    // 以键值对的形式返回，可用的api值true，不可用为false
                                    // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                                    console.log(res, 'checkJsApi')
                                }
                            });
                            let shareConfig = {
                                title:  res.data.data.share.fx_share_title,
                                desc: res.data.data.share.fx_share_desc,
                                link:  that.getUrlParameter(),
                                imgUrl: res.data.data.share.fx_share_logo,
                                type: '',
                                dataUrl: '',
                                success: function (r) {
                                    console.log("分享成功"+JSON.stringify(r));
                                },
                                cancel: function (rr) {
                                    console.log('分享错误'+JSON.stringify(rr));
                                }
                            }
                            that.getIcoUrl(res.data.data.share.ico);
                            wx.ready(function () {
// that.getHtml();
                                //分享到朋友圈
                                // wx.onMenuShareTimeline(shareConfig);
                                //分享给朋友
                                // wx.onMenuShareAppMessage(shareConfig);
                                // wx.onMenuShareQQ(shareConfig); // QQ
                                wx.updateAppMessageShareData(shareConfig);
                                wx.updateTimelineShareData(shareConfig);
                            });
                            wx.error(function(res){
                                console.log(123);
                                console.log(res);
                                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                            })
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            showDetail(id,roomId) {
                if(this.getToken()) {
                    let that = this;
                    liveReservationStatus({room_id:id})
                        .then(res => {
                            if(res.data.code == 1) {
                                //进入直播
                                that.getUrl(id);
                            } else {
                                Toast.fail(res.data.msg);
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                            Toast.fail('请求错误');
                        })
                } else {
                    Toast.fail('请先登录');
                    this.$router.push({
                        name: 'User',
                        query: {redirect: this.$route.name,isShowModal:true}
                    })
                }
            },
            getUrl(id) {
                let that = this;
                appletUrl({id})
                    .then(res => {
                        if(res.data.code == 1) {
                            //进入直播
                            window.location.href=res.data.data.url;
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    })
                    .catch(err => {
                        Toast.fail('请求直播链接错误');
                    })
            },
        },
        created() {

            this.getData();
            this.title = this.getBranchCompany()
            this.onWxConfig();
        },
        mounted() {
            this.title = this.getBranchCompany()
        },
        components: {

        },

    }
</script>
<style lang="less">
    .home {
        background-color: #ffffff;
        // overflow: hidden;
        padding: 0 0.8rem 0.8rem 0.8rem;
    }
    .topCenter{
        font-size: 2.2rem;
        font-weight: 600;
        /* text-align: center; */
        display: flex;
        color: #323232;
        align-items: center;
    }
    .topCenter .van-icon {
        -moz-transform:rotate(90deg);
        -webkit-transform:rotate(90deg);
    }
    .topRight {
        width: 6rem;
        font-size: 2rem;
    }
    .topRight .van-grid-item__text  {
        font-size: 1.4rem;
    }

    .live_column{
        padding-bottom: 3rem;
        padding-top: 6rem;
    }

    .live_column .van-cell::after {
        border-width: 0;
    }

    .live_column .title {
        padding: 1.8rem .6rem 1rem .6rem;
        height: 6rem;
        line-height: 6rem;
    }

    .live_column .title .van-cell__title {
        font-size: 2.4rem;
        font-weight: 600;
    }

    .live_column .title .van-cell__value {
        font-size: 1.8rem;
        overflow: visible;
    }

    .live_column .title .van-icon {
        font-size: 2rem;
        line-height: 2rem;
        height: 2rem;
    }

    .live_column .title1 {
        padding: .6rem .6rem 0 .6rem;
        height: 3.6rem;
    }

    .live_column .title1 .van-cell__title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 2.4rem;
    }

    .live_column .title1 .van-cell__value {
        font-size: 1.8rem;
        height: 4rem;
        overflow: visible;
    }

    .live_column .shop-card {
        background-color: #ffffff;
        height: 14rem;
        padding: 0 0.6rem;
        margin-bottom: 1.8rem;
    }

    .live_column .shop-card .van-card__thumb {
        width: 13.5rem;
        height: 14rem;
        margin-right: .6rem;
    }

    .live_column .shop-card .van-card__thumb img {
        border-radius: .8rem;
    }

    .live_column .shop-card .van-card__title {
        font-size: 2rem;
        line-height: 3rem;
        height: 3.2rem;
        max-height: 3.2rem;
    }

    .live_column .shop-card .van-card__desc {
        font-size: 1.8rem;
        line-height: 3rem;
        height: 3rem;
        max-height: 3rem;
    }

    .live_column .shop-card .tags {
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        height: 3.6rem;
        line-height: 3rem;
    }

    .live_column .shop-card .tags .van-tag {
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-right: .4rem;
        padding: 0 .8rem;
        height: 2.4rem;
    }

    .live_column .shop-card .van-card__bottom {
        height: 3.2rem;
    }

    .live_column .shop-card .van-card__bottom .van-button {
        float: right;
        padding: 0 .8rem;
        font-size: 1.4rem;
        height: 3rem;
    }
    .live_column .article {
        margin-bottom: .8rem;
        // padding-bottom: 0px;
        // height: 90px;
        background-color: #ffffff;
        width: 50%;
        float: left;
    }

    .article::after {
        content: '';
        width: 96%;
        height: 0;
        display: block;
        margin: .6rem auto;
        border-bottom: none
    }

    .article .arc_mid {
        padding: .6rem;
    }

    .article .arc_mid .van-cell {
        padding: 0 .2rem;
        display: block !important;
    }

    .live_column .article .arc_mid .van-cell .van-cell__label .flag-title {
        height: 3rem;
        line-height: 3rem;
        font-size: 2rem;
        color: #333333;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    .live_column .article .arc_mid .van-cell .van-cell__title .custom-title {
        height: 3.5rem;
        line-height: 3.2rem;
        font-size: 2rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    .live_column .article .arc_mid .van-cell .van-cell__title .play {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 44%;
        left: 40%;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 100%;
    }

    .live_column .article .arc_mid .van-cell .van-cell__title .play_buy {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 35%;
        left: 25%;
        margin: 0 auto;
        color: #ffffff;
        background-color: rgba(0, 0, 0, .4);
        font-size: 2rem;
        height: 4.5rem;
        padding: 0 1.5rem;
        border-color: rgba(0, 0, 0, .4);;
    }

    .live_column .article .arc_mid .van-cell .van-cell__title .play_buy .van-button__content {
        font-size: 2rem;
    }

    .live_column .article .arc_mid .van-cell .van-cell__title .live_flag {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 4%;
        left: 2%;
        margin: 0 auto;
        font-size: 2rem;
        height: 3rem;
        padding: 0 .8rem;
        border-radius: .6rem;
    }

    .play_green {
        color: #00ff08 !important;
    }

    .live_column .article .arc_mid .van-cell .van-cell__title .datetime {
        background-color: rgba(0, 0, 0, 0.5);
        height:3rem;
        width:100%;
        line-height: 3rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: 0rem;
        text-align: center;
    }

</style>

