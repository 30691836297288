<template>
  <div>
    <van-nav-bar class="cart-title" title="笔记详情" fixed left-arrow @click-left="onBack" />
    <div class="questionDetail">
                <div class="courseList1">
                    <van-cell center class="answering">
                        <template #title>
                            <van-tag type="primary">笔记</van-tag>
                            <span class="custom-title">2022年中药学专业知识一（基础班）</span>
                        </template>
                    </van-cell>

                    <van-cell-group inset>
                      <van-field
                        v-model="message"
                        rows="5"
                        autosize
                        type="textarea"
                        maxlength="180"
                        placeholder="请输入"
                        show-word-limit
                      />
                    </van-cell-group>

                    <van-cell center title="公开笔记" class="openNote">
                      <template #right-icon>
                        <van-switch v-model="checked" size="20" />
                      </template>
                    </van-cell>
                </div>
                <div style="margin: 2rem;">
                  <van-button round block type="info" native-type="submit">
                    提交
                  </van-button>
                </div>
                <!-- <van-empty image-size="30rem" description="暂无答疑问题"/> -->
    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
export default {
  name: 'MyNoteDetail',
  metaInfo: {
    title: '笔记详情', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      checked:'',
    }
  },

  methods: {

    onBack() {
      history.back();
    },

  },
}
</script>

<style lang = "less" >
    .questionDetail {
        margin: 6rem 1rem 1rem 1rem;
        padding: 1rem 0;
    }
    .questionDetail .courseList1 {
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }
    .questionDetail .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }
    .questionDetail .courseList1 .van-cell::after {
        border-width: 0.2rem !important;
    }

    .questionDetail .courseList1 .answering .custom-title{
        font-size: 1.6rem;
        padding-right: 1rem;
        color: #646566;
    }
    .questionDetail .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .questionDetail .courseList1 .answering .van-cell__title .van-tag--primary {
        border-radius: 0.4rem;
    }
    .questionDetail .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding-top: 0.5rem;
    }
    .questionDetail .courseList1 .project_tag{
      position: absolute;
      right: 1rem;
      float: right;
      padding: 0.3rem 0.5rem !important;
      font-size: 1.4rem !important;
      z-index: 99999;
      border-top-right-radius: 1rem;
    }
    .questionDetail .courseList1 .van-field__body textarea {
      min-height: 15rem;
      line-height: 3rem;
      font-size: 1.8rem;
    }
    .questionDetail .courseList1 .openNote .van-cell__title {
      font-size: 1.6rem;
    }
    .questionDetail .courseList1 .openNote .van-switch {
      background-color: #969799;
    }
    .questionDetail .courseList1 .openNote .van-switch--on {
      background-color: #1989fa;
    }

</style>