<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        问题详情
      </template>
    </van-nav-bar>
    <div class="questionDetail">
        <div style="padding-top: 0.5rem;">
                <div class="courseList1">
                    <!-- <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">中药学专业知识一</van-tag> -->
                    <van-cell center class="answering">
                        <template #icon>
                            <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png" />
                        </template>
                        <template #title>
                            <span class="custom-title">{{info.username}}</span><br>
                            <van-tag color="#f8f8f8" text-color="#969799">{{info.create_time ? info.create_time : '无'}} - 来自{{info.source}}</van-tag>
                        </template>
                        <template #label>
                            <span>{{info.title}}</span>
                        </template>
                    </van-cell>
                </div>
                <van-divider />
                <div class="courseList1"   v-if="info.children">
                    <!-- <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">中药学专业知识一</van-tag> -->
                    <van-cell center class="answering">
                        <template #icon>
                            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/item-askq.png" />
                        </template>
                        <template #title>
                            <span class="custom-title">{{info.children ? info.children.real_name : '无'}}回复</span>
                            <van-tag color="#f8f8f8" text-color="#969799">{{info.children ? info.children.create_time : '无'}}</van-tag>
                        </template>
                        <template #label>
                            <span>{{info.children ? '亲爱的同学，很高兴能为您提供帮助，'+info.children.title : '无'}}</span>
                        </template>
                    </van-cell>
                </div>
                <!-- <van-empty image-size="30rem" description="暂无答疑问题"/> -->
        </div>


    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
let formName;
import { repliedInfo } from '@/request/api'
import {Toast} from "vant";
export default {
    beforeRouteEnter(to, from, next) {
        let that = this;
        console.log(to);
        console.log(from);
        formName = from.name;
        // console.log(next);
        // if (!to.params || !to.params.id) {
        //     next({
        //         path: from.name,
        //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
        //     })
        // }
        next();
    },
  name: 'CourseQuestionDetail',
  metaInfo: {
    title: '我的订单', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
        commodityId:'',
        courseId:'',
        courseWareId:'',
        id:'',
        info: []
    }
  },
    methods: {

        onBack() {
            let commodityId = this.commodityId;
            let courseId = this.courseId;
            let courseWareId = this.courseWareId;
            this.$router.push({
                name:formName,
                params:{
                    id:courseId,
                    commodityId,
                    coursewareId:courseWareId
                }
            })
        },
        getRepliedInfo() {
            let that = this;
            let id = that.id;
            repliedInfo({id}).then(res => {
                if (res.data.status == 1) {
                    that.info = res.data.data;
                } else {
                    Toast.fail(res.data.msg)
                }
            })
        },
    },
  created() {
      if(this.$route.params.commodityId) {
          this.commodityId = this.$route.params.commodityId;
      } else {
          this.commodityId = this.$route.query.commodityId;
      }
      if(this.$route.params.courseId) {
          this.courseId = this.$route.params.courseId;
      } else {
          this.courseId = this.$route.query.courseId;
      }
      if(this.$route.params.courseWareId) {
          this.courseWareId = this.$route.params.courseWareId;
      } else {
          this.courseWareId = this.$route.query.courseWareId;
      }
      if(this.$route.params.id) {
          this.id = this.$route.params.id;
      } else {
          this.id = this.$route.query.id;
      }
      this.getRepliedInfo();
  },
}
</script>
 
<style lang = "less" >
    .questionDetail {
        margin: 6rem 1rem 1rem 1rem;
        padding: 1rem 0;
    }
    .questionDetail .courseList1 {
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }
    .questionDetail .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }
    .questionDetail .courseList1 .van-cell::after {
        border-width: 0.5rem !important;
    }

    .questionDetail .courseList1 .answering .custom-title{
        font-size: 1.8rem;
        padding-right: 1rem;
        color: #646566;
    }
    .questionDetail .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .questionDetail .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding-top: 0.5rem;
    }
    .questionDetail .courseList1 .project_tag{
      position: absolute;
      right: 1rem;
      float: right;
      padding: 0.3rem 0.5rem !important;
      font-size: 1.4rem !important;
      z-index: 9;
      border-top-right-radius: 1rem;
    }


</style>
