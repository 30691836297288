<template>
    <div>
        <van-nav-bar class="cart-title" title="做题记录" fixed left-arrow @click-left="onBack"/>
        <div class="questionRecord" v-if="data">
            <div class="list" v-for="(item,index) in data" :key="index">
                <van-cell center class="cellBox">
                    <template #title>
                        <span style="color:#1989fa;font-weight:600;">日期：{{item.time}}</span>
                    </template>
                    <template #extra>
                        <span style="font-size:1.6rem;color:#969799"><van-icon name="info-o"
                                                                               color="#1989fa"/>向左滑动删除</span>
                    </template>
                </van-cell>
                <van-collapse v-model="activeNames" class="collapseBox">
                    <van-swipe-cell :before-close="beforeClose" :name="item2.id" v-for="(item2,index2) in item.data">
                        <van-collapse-item :name="`${index}`+`${index2}`">
                            <template #title>
                                <van-cell center>
                                    <template #title>
                                        <div class="paperName">{{item2.title}}</div>
                                        <span class="paperType">{{item2.test_paper_type}}</span>
                                    </template>
                                </van-cell>
                            </template>
                            <div class="content">
                                <p>题目数量：{{item2.question_num}}</p>
                                <p>答题时长：{{item2.question_time}}</p>
                                <p>正确率：{{item2.correct_rate}}</p>
                                <p>本次得分：{{item2.getscore}}</p>
                                <van-row>
                                    <van-col span="12" style="text-align:center">
                                        <van-button round type="info" size="mini" style="width:95%" @click="result(item2.id,item2.test_paper_id,item2.examination_type)">做题结果</van-button>
                                    </van-col>
                                    <van-col span="12" style="text-align:center">
                                        <van-button round type="primary" size="mini" style="width:95%" @click="analysis(item2.id)">查看解析</van-button>
                                    </van-col>
                                </van-row>
                            </div>
                        </van-collapse-item>
                        <template #right>
                            <van-button text="删除" type="danger" class="delete-button"/>
                        </template>
                    </van-swipe-cell>
                </van-collapse>
                <van-divider style="height:0.6rem;background-color:#f5f5f5;color:#f5f5f5"/>
            </div>

        </div>

        <div v-else>
            <van-empty
                    description="暂无做题记录"
                    image="http://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                    image-size="24rem">
                <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">
                    联系客服
                </van-button>
            </van-empty>
        </div>
        <van-popup class="resultPop" v-model="showSTQKFK" position="right" :style="{ height: '100%', width: '100%' }">
            <zxksKsdtqk :testPaperAnswerId="testPaperAnswerId" :testPaperId="testPaperId" :type="examination_type" :url="url" :title="title" @againQuestion="againQuestion"></zxksKsdtqk>
        </van-popup>
        <van-popup class="showTest2" v-model:show="showTestView" position="bottom" closeable close-icon-position="top-left" close-icon="arrow-left" :style="{ height: '100%',width:'100%' }">
            <van-cell center title="试题详情" style="position: fixed;top: 0;text-align: center;"></van-cell>
            <!-- 试题 -->
            <div class="result_testContent">
                <viewResolution :testPaperId="testPaperAnswerId" :url="url"></viewResolution>
            </div>
        </van-popup>
    </div>


</template>

<script>
    import {questionRecord, questionRecordDel} from "@/request/api";
    // 考试结束答题情况弹框
    // 导航栏
    import comnavBar from '@/components/com_navBar'
    // 查看提示弹框
    import comZxksTip from '@/components/com_zxksTip'
    // 考试结束弹框
    import comKnow from '@/components/com_know'
    // 考试结束弹框
    import comOkCancel from '@/components/com_ok_cancel'
    // 考试结束答题情况弹框
    import zxksKsdtqk from '@/modules/zxks_ksdtqk'
    // 单选题
    import comRadio from '@/components/com_radio'
    // 多选题
    import comCheckbox from '@/components/com_checkbox'
    // 配伍选择题-单选
    import comDataRadio from '@/components/com_data_radio'
    // 配伍选择题-多选
    import comDataCheckbox from '@/components/com_data_checkbox'
    // 判断题
    import comJudge from '@/components/com_judge'
    import viewResolution from '@/components/viewResolution'
    // 简答题
    import comshortAnswer from '@/components/com_shortAnswer'
    import {questionsList, testcollect, testPaperPracticeQuestionPost} from "@/request/api";
    import {Toast} from "vant";

    export default {
        components: {
            comnavBar,
            comRadio,
            comCheckbox,
            comDataRadio,
            comDataCheckbox,
            comJudge,
            comshortAnswer,
            comZxksTip,
            comKnow,
            comOkCancel,
            zxksKsdtqk,
            viewResolution
        },
        metaInfo() {
            return {
                title: this.title, //设置页面title
            }
        },
        data() {
            return {
                title:'做题记录',
                activeNames: [],
                data: [],
                testPaperAnswerId: '',
                testPaperId: '',
                showSTQKFK: false,
                examination_type: 1,
                url:'QuestionRecord',
                showTestView: false,
                appOrH5:false
            }
        },
        created() {
            this.getQuestionRecord();
            this.appOrH5 = this.$route.query.appOrH5;
        },
        mounted() {
        },
        methods: {
            onBack() {
                history.back();
            },
            beforeClose({position, instance,name}) {
                let that = this;
                switch (position) {
                    case 'left':
                    case 'cell':
                    case 'outside':
                        instance.close();
                        break;
                    case 'right':
                        this.$dialog.confirm({
                            message: '确定删除吗？',
                        }).then(() => {
                            console.log('删除');
                            questionRecordDel({id: name})
                                .then(res => {
                                    if (res.data.code == 1) {
                                        Toast.success('删除成功')
                                        that.getQuestionRecord();
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    Toast.fail('删除错误');
                                })
                        });
                        break;
                }
            },
            getQuestionRecord() {
                let that = this;
                questionRecord({})
                    .then(res => {
                        if (res.data.code == 1) {
                            console.log(res.data.data);
                            that.data = res.data.data;
                            console.log(that.data.length);
                        } else {

                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            mySchool() {
                this.$router.push('/MySchool')
            },
            againQuestion() {
                this.showSTQKFK = false;
                this.testPaperAnswerId = '';
                this.testPaperId = '';
                this.examination_type = '';
            },
            result(id,test_paper_id,examination_type) {
                this.testPaperAnswerId = id;
                this.testPaperId = test_paper_id;
                this.examination_type = examination_type;
                this.showSTQKFK = true;
                console.log(this.showSTQKFK);
            },
            analysis(id) {
                this.testPaperAnswerId = id;
                this.showTestView = true;
            }
        },
    }
</script>
<style lang="less" scoped>

    .questionRecord {
        padding-top: 6.5rem;
        background-color: #ffffff;

        .cellBox {
            min-height: 4rem !important;
            padding: 0 1rem;
        }

        .collapseBox {
            padding: 1rem;
        }

        .paperName {
            font-size: 1.8rem;
        }

        .paperType {
            font-size: 1.6rem;
            color: #969799;
        }

        .content {
            padding: 1rem;
        }

        .content p {
            line-height: 3rem;
            font-size: 1.6rem;
        }
        .result_testContent {
            width: 100%;
            /* width: 95%; */
            /* margin: 1rem; */
            padding-top: 6rem;
            padding-bottom: 0.5rem;
            border-bottom: 0.1rem solid #eeeeee;
        }
    }


</style>
