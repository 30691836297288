<template>
  <div class="com_navBar test-dark">
    <van-nav-bar :fixed="true" :title="Title" v-if="backType=='0'" left-arrow @click-left="onClickLeft"/>
    <van-nav-bar
      v-if="backType=='1'"
      :title="Title"
      :fixed="true"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
    <template #right>
      
    <!-- 气泡弹出窗 -->
    <van-popover v-model:show="showPopover" placement="bottom-end">
      <div class="popover-div">
          <van-row class="row1">
            <van-col span="24"><van-button class="row1Btn" type="default" icon="http://cdnoss.ksyt.com.cn/wxImages/icon_jsq.png" @click="jisuanqiBtn">计算器</van-button></van-col>
          </van-row>
          <!-- <van-row class="row2">
            <van-col span="8"><van-button type="default">大</van-button></van-col>
            <van-col span="8"><van-button type="warning">中</van-button></van-col>
            <van-col span="8"><van-button type="default">小</van-button></van-col>
          </van-row>
          <van-row class="row3">
            <van-col span="12"><van-button type="warning" class="row3Btn1">日间模式</van-button></van-col>
            <van-col span="12"><van-button type="default" class="row3Btn2">夜间模式</van-button></van-col>
          </van-row> -->
      </div>
      <template #reference>
        <van-icon name="setting-o" />
      </template>
    </van-popover>
    </template>
    </van-nav-bar>

  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  props: ['backType', 'Title'],
  data() {
    return {
      showPopover: false,
    }
  },
  watch: {},
  created() {},
  methods: {
    // 返回
    onClickLeft() {
      this.$emit('onClickLeft')
    },
    // 显示设置
    onClickRight() {
      // this.$emit('onClickRight')
      this.showPopover = !this.showPopover;
    },
    jisuanqiBtn(){
      this.showPopover = !this.showPopover;
      this.$emit('jisuanqiBtn')
      // console.log('jisuanqi')
    }
  }
}
</script>
 
<style scoped>
.com_navBar /deep/ .van-nav-bar {
  /* background-color: #ee0a24; */
  z-index: 1000;
}
.com_navBar /deep/ .van-nav-bar__title {
  color: #646566;
  font-size: 1.8rem;
}
.com_navBar /deep/ .van-nav-bar__text {
  color: #646566;
      font-size: 1.8rem;
}
.com_navBar /deep/ .van-icon-arrow-left::before {
  font-size: 3.2rem;
    padding: 0.5rem;
    color: #333333;
}
.com_navBar /deep/ .van-icon-setting-o::before {
  font-size: 3rem;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    color: #333333;
}
.popover-div {
  width: 24rem;
  /* border: 0.1rem solid #dbdbdb ; */
  border-radius: 1rem;  
  background-color: #dbdbdb ;
}
.popover-div .van-row .van-col {
  min-height: 4rem !important;
  font-size: 1.8rem !important;
  background-color: #ffffff;
  /* border-bottom: 0.1rem solid #dbdbdb; */
}
.popover-div .row1 .van-col {
  border-bottom: 0.1rem solid #dbdbdb;
}
.popover-div .row2 .van-col {
  border-bottom: 0.1rem solid #dbdbdb;
}
.popover-div .van-row .van-col .van-button {
  border-width: 0 !important;
}
.popover-div .row1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popover-div .row1 .row1Btn {
  width: 100%;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.popover-div .row2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popover-div .row2 .van-button {
  width: 100%;
  border-radius: 0 !important;
}
.popover-div .row3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popover-div .row3 .row3Btn1 {
  width: 100%;
  border-bottom-left-radius: 1rem !important;
}
.popover-div .row3 .row3Btn2 {
  width: 100%;
  border-bottom-right-radius: 1rem !important;
}
.popover-div .van-button--default {
    background-color: #f5f5f5 !important;
    border-width: 0 !important;
}
.popover-div .van-button--warning {
    border: 0 solid #ff976a  !important;
}
.popover-div .van-button {
    border-radius: 0rem !important;
}
[class*='van-hairline']::after {
  /* border: 1px solid #ee0a24; */
}
</style>
