<template>
    <div>
        <van-nav-bar class="cart-title" title="课程更新进度" fixed left-arrow @click-left="onBack"/>
        <van-row>
            <!-- <van-col span="6">
                <img :src="avatarSrc">
            </van-col> -->
            <van-col span="24">
                <van-tag round size="large" color="#f5f5f5" text-color="#333333" class="nickname">2023执业药师课程更新进度</van-tag>
            </van-col>
        </van-row>
        <!-- <van-divider
        :style="{ color: '#1989fa', borderColor: '#656466', padding: '2rem' }"
        >
        </van-divider> -->
        <van-tabs v-model="active" class="updatePrej" animated swipeable>
            <van-tab :title="item.project" v-for="(item, index) in item1" :key="index">
                <div class="vant-table">
                    <table cellspacing="0" :style="bgcolor" style="width:100%" class="table">
                        <tr>
                            <th class="th" v-for="(item, index) in option.column" :key="index">{{ item.label }}</th>
                        </tr>
                        <tr v-for="(item, index) in item.tableData" :key="index" class="list-tr">
                            <td v-for="(context, i) in option.column" :key="i">{{ item[context.prop] }}</td>
                        </tr>
                    </table>
                </div>
            </van-tab>
        </van-tabs>
        <van-divider
        :style="{ color: '#1989fa', borderColor: '#656466', padding: '1rem' }"
        >
        </van-divider>
        <van-tabs v-model="active" class="updatePrej" animated swipeable>
            <van-tab :title="item.project" v-for="(item, index) in item2" :key="index">
                <div class="vant-table">
                    <table cellspacing="0" :style="bgcolor" style="width:100%" class="table">
                        <tr>
                            <th class="th" v-for="(item, index) in option.column" :key="index">{{ item.label }}</th>
                        </tr>
                        <tr v-for="(item, index) in item.tableData" :key="index" class="list-tr">
                            <td v-for="(context, i) in option.column" :key="i">{{ item[context.prop] }}</td>
                        </tr>
                    </table>
                </div>
            </van-tab>
        </van-tabs>

        <!-- <div class="signOut">
            <van-button round type="info" icon="share-o" >分享给好友</van-button>
        </div> -->
    </div>
</template>

<script>

    export default {

        metaInfo() {
            return {
                title: this.title+'-我的', //设置页面title

            }
        },
        data() {
            return {
                bgcolor:'background-color:#dddddd',
                // title: this.getBranchCompany(),
                item1:[
                    {
                    project:'中药一',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新2讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'中药二',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新4讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'中药综合',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新3讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'法规',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新6讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                ],
                item2:[
                    {
                    project:'西药一',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新2讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'西药二',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新4讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'西药综合',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新3讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                    {
                    project:'法规',
                    tableData: [
                        {
                        courseName: "导学班",
                        updateContent: "已更新6讲",
                        state: '已完成',
                        estimate: '12月底'
                        },
                        {
                        courseName: "基础班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '1月底'
                        },
                        {
                        courseName: "突破班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '2月底'
                        },
                        {
                        courseName: "模考班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '6月底'
                        },
                        {
                        courseName: "点睛班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '9月底'
                        },
                        {
                        courseName: "应是技巧班",
                        updateContent: "",
                        state: '未开始',
                        estimate: '10月底'
                        },
                    ],
                    },
                ],
                //th
                option: {
                    column: [
                        {
                            label: '阶段名称',
                            prop: 'courseName'
                        },
                        {
                            label: '更新内容',
                            prop: 'updateContent'
                        },
                        {
                            label: '状态',
                            prop: 'state'
                        },
                        {
                            label: '预计更新',
                            prop: 'estimate'
                        }
                    ]
                },
            }
        },
        created() {

        },
        methods: {
            onBack() {
                history.back();
                window.android.back();
            },
            change(){},
            onChange(){},
            
        },
    }
</script>

<style lang="less" scoped>
    .echarts_home {
        //   width: 500px;
        height: 30rem;
        margin: auto;
        padding: 2rem 0rem 2rem 2rem;
        // border: 0.1rem solid lightcoral;

        //  宽高是必须给的，可以给百分比、具体的像素等....
        .barChart {
            width: 100%;
            height: 100%;
        }
    }

    .van-row {
        height: 6rem;
        padding: 6.5rem 2rem 0.5rem 2rem;
        background-image: url('http://cdnoss.ksyt.com.cn/wxImages/assets/images/tbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        text-align: center;

        .van-col {
            line-height: 6rem;

            img {
                width: 10rem;
                border-radius: 50%;
                display: block;
            }

            .nickname{
                font-size: 2rem !important;
            }
        }
    }


    .signOut {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 10rem;

        .van-button {
            width: 90%;
        }
    }
    .shuju {
        height: 10rem;
        margin: 0 auto;
        text-align: center;
        span{
            line-height:5rem;
        }
        b {
            font-weight: 600;
            font-size: 2.8rem;
        }
        .van-cell__label {
            font-size: 1.4rem;
        }
    }

    .vant-table {
        padding: 0.5rem 1rem 1rem 1rem;
        background-color: #ffffff;
        .table {
            border-radius: .5rem;
            .th {
            height: 5rem;
            line-height: 5rem;
            font-size: 1.8rem;
            color: #ff6600;
            background-color: #f5f5f5;
            text-align: center;
            // border-top-left-radius: .2rem;
            // border-top-right-radius: .2rem;
            }
            .list-tr {
            height: 5rem;
            line-height: 5rem;
            font-size: 1.6rem;
            background-color: #f5f5f5;
            text-align: center;
            }
            .list-tr:nth-child(2n) {
            background-color: #ffffff;
            }
            td{
                min-width: 8rem;
                text-align: center;
            }
        }
    }
    .van-divider {
        font-size: 2rem;

    }
    

</style>
