<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        课程协议
      </template>
    </van-nav-bar>
    <div class="courseAgreement" style="padding: 6rem 0 8rem 0;">
          <div style="padding: 1rem;">
              <van-card
                class="shop-card"
                desc="课程有效期：2023.11.15"
                title="xxxx班次"
                thumb="http://ksytimg.oss-cn-beijing.aliyuncs.com/commodity/20220629/e5df92677e36de11e96da228f7724234.jpg"
              >
                <template #tags>
                  <van-tag round class="vantag">一年班次</van-tag>
                </template>
              </van-card>
              <div class="vanform">
                <van-form @submit="onSubmit">
                  <!-- 通过 pattern 进行正则校验 -->
                  <van-field
                    v-model="text"
                    required
                    name="name"
                    placeholder="请输入真实姓名"
                    :rules="[{ required: true, }]"
                  >
                  <template #label><span style="font-weight:600">甲方姓名</span></template>
                  </van-field>
                  <!-- 通过 validator 进行函数校验 -->
                  <van-field
                    v-model="text1"
                    required
                    name="username"
                    label="听课账号"
                    placeholder="请输入听课账号"
                    :rules="[{ required: true, }]"
                  />
                  <!-- 通过 validator 进行异步函数校验 -->
                  <van-field
                    v-model="number"
                    type="number"
                    required
                    name="idNumber"
                    label="身份证号"
                    placeholder="请输入身份证件号码"
                    :rules="[{ required: true,}]"
                  />
                  <!-- 通过 validator 进行异步函数校验 -->
                  <van-field
                    v-model="tel"
                    type="tel"
                    required
                    name="phoneNumber"
                    label="手机号码"
                    placeholder="请输入手机号码"
                    :rules="[{ required: true, }]"
                  />

                  
                  <div class="agreecontent">
                    <van-cell>
                      <template #title>
                        乙方：<span style="color:#656466">xxxxxxxx公司</span>
                      </template>
                      <template #label>
                        <div>地址：<span style="color:#969799">xx省xx市，xx地区xx工作室</span></div>
                        <div>电话：<span style="color:#969799">188108******</span></div>
                      </template>
                    </van-cell>
                  
                    <van-cell center title="" >
                      <template #label>
                        甲、乙双方本着平等互利的原则，经友好协商，依照相关法律法规，同意按照本协议的条款，由乙方所拥有的xxx网校为甲方提供 2021-2022 年xx考试全国统一考试考前辅导服务，具体条款如下：
                      </template>
                    </van-cell>

                    <van-cell center title="" >
                      <template #label>
                        甲、乙双方本着平等互利的原则，经友好协商，依照相关法律法规，同意按照本协议的条款，由乙方所拥有的xxx网校为甲方提供 2021-2022 年xx考试全国统一考试考前辅导服务，具体条款如下：
                      </template>
                    </van-cell>
                    
                    <!-- <van-field
                      v-model="sms"
                      required
                      center
                      clearable
                      label="甲方签名"
                      placeholder="请输入真实姓名"
                      :rules="[{ required: true, }]"
                    >
                      <template #button>
                        <van-button round size="small" type="warning">申请续读</van-button>
                      </template>
                    </van-field> -->

                    <van-cell center title="甲方签名" >
                      <template #label>
                        
                      <!-- <section class="signature"> -->
                        <div class="signatureBox">
                            <div class="canvasBox" ref="canvasHW">
                                <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd' @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
                                  <van-row  class="btnBox">
                                      <van-col span="12">
                                        <van-button round size="small" type="danger" @click="overwrite">重写</van-button>
                                      </van-col>
                                      <van-col span="12">
                                        <van-button round size="small" type="primary"  @click="commit">提交签名</van-button>
                                      </van-col>
                                   </van-row> 
                            </div>
                        </div>
                        <!-- <img class="imgCanvas" :src="imgUrl"> -->
                        <!-- </section> -->
                      </template>
                      <!-- <template #right-icon>
                        <van-icon :name="shenhe" class="shenhe"></van-icon>
                      </template> -->
                    </van-cell>

                    <van-cell center title="乙方（盖章）" >
                      <template #label>
                        xxxxxxxx公司
                      </template>
                      <template #right-icon>
                        <van-icon :name="shenhe" class="shenhe"></van-icon>
                      </template>
                    </van-cell>

                    <van-cell center>
                      <template #title>
                        协议签订：学员线上签署<span style="color:#07c160;padding-left: 1rem;">续读申请已通过</span>
                      </template>
                      <template #label>
                        <div>签订时间：2022-05-19 16:40:20</div>
                        <div>续读申请：2022-05-19 16:40:20</div>
                      </template>
                    </van-cell>

                  </div>



                  <div class="subbtn">
                    <van-button round block type="info" size="large" native-type="submit">提交</van-button>
                  </div>
                </van-form>
              </div>
          </div>
    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
import { Toast } from 'vant';
export default {
  name: 'courseAgreement',
  metaInfo: {
    title: '课程协议', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      tel: '',
      text: '',
      text1: '',
      number: '',
      shenhe: "http://cdnoss.ksyt.com.cn/wxImages/ksyt_gongzhang.png",

      stageInfo:'',
        imgUrl:'',
        client: {},
        points: [],
        canvasTxt: null,
        startX: 0,
        startY: 0,
        moveY: 0,
        moveX: 0,
        endY: 0,
        endX: 0,
        w: null,
        h: null,
        isDown: false,
        isViewAutograph: this.$route.query.isViews > 0,
        contractSuccess: this.$route.query.contractSuccess
    }
  },
  // created() {

  // },
  mounted() {
      let canvas = this.$refs.canvasF
      canvas.height = this.$refs.canvasHW.offsetHeight - 50
      canvas.width = this.$refs.canvasHW.offsetWidth - 5
      this.canvasTxt = canvas.getContext('2d')
      this.stageInfo = canvas.getBoundingClientRect()
  },

  methods: {
    onSubmit(values) {
      console.log('submit', values);
    },

    onBack() {
      history.back();
      window.android.back();
    },

    
      //mobile
      touchStart(ev) {
        ev = ev || event
        ev.preventDefault()
        if (ev.touches.length == 1) {
          let obj = {
            x: ev.targetTouches[0].clienX,
            y: ev.targetTouches[0].clientY,
          }
          this.startX = obj.x
          this.startY = obj.y
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.points.push(obj)
        }
      },
      touchMove(ev) {
        ev = ev || event
        ev.preventDefault()
        if (ev.touches.length == 1) {
          let obj = {
            x: ev.targetTouches[0].clientX - this.stageInfo.left,
            y: ev.targetTouches[0].clientY - this.stageInfo.top
          }
          this.moveY = obj.y
          this.moveX = obj.x
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.startY = obj.y
          this.startX = obj.x
          this.points.push(obj)
        }
      },
      touchEnd(ev) {
        ev = ev || event
        ev.preventDefault()
        if (ev.touches.length == 1) {
          let obj = {
            x: ev.targetTouches[0].clientX - this.stageInfo.left,
            y: ev.targetTouches[0].clientY - this.stageInfo.top
          }
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.points.push(obj)
        }
      },
      //pc
      mouseDown(ev) {
        ev = ev || event
        ev.preventDefault()
        if (1) {
          let obj = {
            x: ev.offsetX,
            y: ev.offsetY
          }
          this.startX = obj.x
          this.startY = obj.y
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.points.push(obj)
          this.isDown = true
        }
      },
      mouseMove(ev) {
        ev = ev || event
        ev.preventDefault()
        if (this.isDown) {
          let obj = {
            x: ev.offsetX,
            y: ev.offsetY
          }
          this.moveY = obj.y
          this.moveX = obj.x
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.startY = obj.y
          this.startX = obj.x
          this.points.push(obj)
        }
      },
      mouseUp(ev) {
        ev = ev || event
        ev.preventDefault()
        if (1) {
          let obj = {
            x: ev.offsetX,
            y: ev.offsetY
          }
          this.canvasTxt.beginPath()
          this.canvasTxt.moveTo(this.startX, this.startY)
          this.canvasTxt.lineTo(obj.x, obj.y)
          this.canvasTxt.stroke()
          this.canvasTxt.closePath()
          this.points.push(obj)
          this.points.push({x: -1, y: -1})
          this.isDown = false
        }
      },
      //重写
      overwrite() {
        this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
        this.points = []
      },
      //提交签名
      commit() {
        this.imgUrl=this.$refs.canvasF.toDataURL();
        console.log(this.$refs.canvasF.toDataURL()) //签名img回传后台
      }
  },
}
</script>
 
<style lang = "less" >
    .courseAgreement .courseData {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 0.5rem;
    }
    .courseAgreement .courseData .van-cell {
        border-radius: 1rem !important;
        padding: 1.5rem 1.5rem 1.5rem .5rem;
    }
    .courseAgreement .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .courseAgreement .courseData .answering .custom-title{
        // font-size: 1.8rem;
        font-weight: 600;
        padding-right: 1rem;
        line-height: 3rem;
    }
    .courseAgreement .courseData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .courseAgreement .courseData .answering .van-cell__label {
        color: #646466;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .courseAgreement .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 99999;
      border-top-left-radius: 1rem;
    }
    .courseAgreement .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .courseAgreement .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .courseAgreement .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .courseAgreement .icon-img{
      width: 8rem;

    }
    .courseAgreement .shop-card {
      padding: 1rem !important;
      border-radius: 1rem;
      min-height: 10rem;
    }
    .courseAgreement .shop-card .van-card__thumb {
        width: 15rem;
        min-height: 10rem;
        margin-right: 0.6rem;
    }
    .courseAgreement .shop-card .van-card__desc {
      line-height: 3rem;
    }
    .courseAgreement .shop-card  .vantag {
      margin-top: 1rem;
      padding: 0.1rem 0.8rem !important;
      line-height: 1.6rem !important;
      font-size: 1.5rem !important;
    }
    .courseAgreement .vanform {
      padding-top: 1rem;
      border-radius: 1rem;
    }
    .courseAgreement .vanform .van-cell {
        min-height: 6rem;
        padding: 0.5rem 1rem !important;
        line-height: 5rem !important;
        font-size: 1.8rem !important;
    }
    .courseAgreement .vanform .van-cell .van-field__label {
      line-height: 5rem;
      padding-left: 1rem;
    }
    .courseAgreement .vanform .van-cell--required::before {
      position: absolute;
      left: 1rem;
      padding-right: 0.5rem;
      color: #ee0a24;
      font-size: 2rem;
      content: '*';
    }
    .courseAgreement .vanform .van-field__error-message {
      color: #ee0a24;
      font-size: 1.4rem;
      text-align: left;
    }
    
    .courseAgreement .agreecontent {
      padding-top: 1rem;
      border-radius: 1rem;
    }
    .courseAgreement .agreecontent .van-cell {
        min-height: 6rem;
        padding: 1.5rem 1rem !important;
        line-height: 5rem !important;
        font-size: 1.8rem !important;
    }
    .courseAgreement .agreecontent .van-cell__label {
        margin-top: 0.5rem;
        color: #656466;
        font-size: 1.8rem;
        line-height: 3.6rem;
    }
    .courseAgreement .agreecontent .shenhe {
        font-size: 8rem;
    }
    .courseAgreement .subbtn {
      position: fixed;
      right: 1rem;
      left: 1rem;
      bottom: 0.5rem;
    }


    .signatureBox {
      width: 100%;
      height: calc(100% - 5rem);
      box-sizing: border-box;
      overflow: hidden;
      background: #fff;
      z-index: 100;
      display: flex;
      flex-direction: column;
    }
    .canvasBox {
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      height: 6rem;
    }
    canvas {
      border: 0.1rem solid #7d7d7d;
      width: 99%;
      height: 100%;
    }
    .btnBox {
      padding: 1rem;
      text-align: center;
    }
    .btnBox .van-button {
      width: 100%;
      font-size: 2rem;
    }
</style>
