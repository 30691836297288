<template>
  <div class="course">
      <van-nav-bar title="精品课程免费试听" class="top" fixed left-arrow @click-left="onBack"/>
      <!-- <van-search v-model="SearchValue"  shape="round" placeholder="请输入搜索关键词1" disabled
        @click="handleClickSearch"
       /> -->
      <transition name="van-slide-right" mode="out-in">
          <router-view></router-view>
      </transition>

      <div class="banner">
          <van-swipe class="my-swipe" :autoplay="3000" height="auto" indicator-color="#1989fa">
              <van-swipe-item v-for="item in banner" :key="item.id">
                  <img :src="item.url" width="100%" style="display: block;" alt/>
              </van-swipe-item>
          </van-swipe>
      </div>

      <!-- <van-grid :column-num="3" :gutter="10" class="projectClass">
          <van-grid-item :class="subjectId ==  ? 'subjectShow' : ''" :text="item.cate_name"  v-for="(item,index) in courseList" :key="index" :class="currentIndex === index ?'activeP':''" @click="aa(index)"/>
      </van-grid> -->
      <div v-if="groupId">
          <van-grid :column-num="3" :gutter="10" id="group_8" class="projectClass group_8" v-if="groupId == 8">
              <van-grid-item :class="subjectId == 798 ? 'subjectShow' : ''" @click="getFreeAudition(798,8)" data-id="798" text="中药一" />
              <van-grid-item :class="subjectId == 799 ? 'subjectShow' : ''" @click="getFreeAudition(799,8)" data-id="799" text="中药二" />
              <van-grid-item :class="subjectId == 800 ? 'subjectShow' : ''" @click="getFreeAudition(800,8)" data-id="800" text="中药综合" />
              <van-grid-item :class="subjectId == 802 ? 'subjectShow' : ''" @click="getFreeAudition(802,8)" data-id="802" text="西药一" />
              <van-grid-item :class="subjectId == 803 ? 'subjectShow' : ''" @click="getFreeAudition(803,8)" data-id="803" text="西药二" />
              <van-grid-item :class="subjectId == 804 ? 'subjectShow' : ''" @click="getFreeAudition(804,8)" data-id="804" text="西药综合" />
              <van-grid-item :class="subjectId == 801 ? 'subjectShow' : ''" @click="getFreeAudition(801,8)" data-id="801" text="法规" />
          </van-grid>
          <van-grid :column-num="2" :gutter="10" id="group_10" class="projectClass group_10"  v-if="groupId == 10">
              <van-grid-item :class="subjectId == 780 ? 'subjectShow' : ''" @click="getFreeAudition(780,10)" data-id="780" text="临床执业医师" />
              <van-grid-item :class="subjectId == 781 ? 'subjectShow' : ''" @click="getFreeAudition(781,10)" data-id="781" text="临床助理医师" />
              <van-grid-item :class="subjectId == 782 ? 'subjectShow' : ''" @click="getFreeAudition(782,10)" data-id="782" text="中医执业医师" />
              <van-grid-item :class="subjectId == 783 ? 'subjectShow' : ''" @click="getFreeAudition(783,10)" data-id="783" text="中医助理医师" />
              <van-grid-item :class="subjectId == 784 ? 'subjectShow' : ''" @click="getFreeAudition(784,10)" data-id="784" text="中西医执业医师" />
              <van-grid-item :class="subjectId == 785 ? 'subjectShow' : ''" @click="getFreeAudition(785,10)" data-id="785" text="中西医助理医师" />
              <van-grid-item :class="subjectId == 786 ? 'subjectShow' : ''" @click="getFreeAudition(786,10)" data-id="786" text="乡村全科助理医师" />
              <van-grid-item :class="subjectId == 787 ? 'subjectShow' : ''" @click="getFreeAudition(787,10)" data-id="787" text="中医专长" />
          </van-grid>
          <van-grid :column-num="1" :gutter="10" id="group_16" class="projectClass group_16" v-if="groupId == 16">
              <van-grid-item :class="subjectId == 776 ? 'subjectShow' : ''" @click="getFreeAudition(776,16)" data-id="776" text="护士资格课程试听" />
          </van-grid>
          <van-grid :column-num="2" :gutter="10" id="group_9" class="projectClass group_9" v-if="groupId == 9">
              <van-grid-item :class="subjectId == 812 ? 'subjectShow' : ''" @click="getFreeAudition(812,9)" data-id="812" text="初级护师" />
              <van-grid-item :class="subjectId == 813 ? 'subjectShow' : ''" @click="getFreeAudition(813,9)" data-id="813" text="主管护师" />
          </van-grid>
          <van-grid :column-num="1" :gutter="10" id="group_51" class="projectClass group_51" v-if="groupId == 51">
              <van-grid-item :class="subjectId == 899 ? 'subjectShow' : ''" @click="getFreeAudition(899,51)" data-id="899" text="健康管理师课程试听" />
          </van-grid>
      </div>
      <div class="courseList" v-if="courseList.length > 0">
          <van-card
                  v-for="(item,index) in courseList"
                  :key="index"
                  class="course-card"
                  :desc="item.course_content"
                  :title="item.title"
                  :thumb="item.photopath"
                  style="padding:1rem 0.6rem !important;"
                  @click="handleClickCourseDetail(item.subject_id,item.stage,item.id,groupId,subjectId)"
          >
              <template #tags>
                  <div class="tags">
                      <van-tag plain round type="warning" v-for="(item3,index3) in item.labelarr"
                               :key="index3">{{item3}}
                      </van-tag>
                  </div>
              </template>
              <template #bottom>
                  <!--            <span class="price">￥{{item.course_price}}</span>-->
                  <van-button round type="danger" size="mini" class="audition">试听学习</van-button>
              </template>
          </van-card>
      </div>
      <div v-else>
          <van-empty
                  description="暂无课程"
                  image="http://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                  image-size="24rem">
          </van-empty>
      </div>


  </div>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import axios from "axios"


  import {GetHomeList, onCourseDetail, onCourseList, onShareUrl} from '@/request/api'
  import Qs from 'qs'
  import wx from 'weixin-js-sdk';

  export default {
      beforeRouteEnter(to, from, next) {
          let that = this;
          console.log(to);
          console.log(from);
          // console.log(next);
          // if (!to.params || !to.params.id) {
          //     next({
          //         path: from.name,
          //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
          //     })
          // }
          next();
      },
      name: 'Audition',
      // metaInfo: {
      //     title: this.website.seo_title, //设置页面title
      //     meta: [{                 //设置页面meta
      //         name: 'keyWords',
      //         content: this.website.seo_keywords
      //     },
      //         {
      //             name: "description",
      //             content: this.website.xue_description,
      //         },
      //     ]
      // },
      metaInfo () {
          return {
              title: this.title, //设置页面title
              meta: [{                 //设置页面meta
                  name: 'keyWords',
                  content: this.website.seo_keywords
              },
                  {
                      name: "description",
                      content: this.website.seo_description,
                  },
              ]
          }
      },
      data() {
          return {
              activeP:'',
              SearchValue: "",
              active: '',
              item: '',
              //轮播图数组
              banner: [],
              show: false,
              website: [],
              courseList: [],
              jssdk: [],
              title: '精品课程免费试听',
              photopath: 'http://cdnoss.ksyt.com.cn/school/51e7b00a0c42d29e1c7ce182ca20634.jpg',
              group_show: 'group_8',
              groupId: '',
              subjectId: ''
          };
      },
      methods: {
        onBack() {
            // history.back();
            this.$router.push('/index');
        },
          handleClickCourseDetail(subjectId, stageId, courseId,groupId2,subjectId2) {
              this.$router.push({
                  name:'AuditionDetail',
                  query:{
                      subjectId,
                      stageId,
                      courseId,
                      groupId2,
                      subjectId2
                  }
              })
              // 修改vuex中的 isShowPopupShow 值为true
              // this.$store.commit("changeIsShowPopupShow", true)
          },
    aa(index) {
      this.currentIndex = index;
    },
          getFreeAudition(subjectId,groupId) {
              let that = this;
              if(!subjectId) {
                  let subjectId2 = that.$route.query.subjectId
                  if(subjectId2) {
                      subjectId = subjectId2;
                  } else {
                      let className = ('group_'+that.groupId);
                      subjectId = document.querySelector('#'+className +' > .van-grid-item').getAttribute('data-id');
                  }
              }
              if(!groupId) {
                  groupId = that.groupId;
              }
              onCourseList({groupId,subjectId})
                  .then(res => {
                      if (res.data.code == 1) {
                          that.courseList = res.data.data.data;
                          that.banner = res.data.data.banner;
                          that.website = res.data.data.website;
                          that.subjectId = subjectId;
                          that.onWxConfig();
                      }
                  })
                  .catch(err => {
                      console.log("error");
                      console.log(err);
                  })
          },
          onWxConfig() {
              console.log(location);
              let that = this;
              let link = location.href.split('#')[0]+'#/audition?groupId='+that.groupId+'&subjectId='+that.subjectId;
              onShareUrl({url: location.href.split('#')[0]})
                  .then(res => {
                      if (res.data.code == 1) {
                          wx.config({
                              debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                              appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                              timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                              nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                              signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                              jsApiList: [
                                  'onMenuShareTimeline',
                                  'onMenuShareAppMessage'
                              ]
                          })

                          console.log(link);
                          wx.ready(function () {
                              //分享到朋友圈
                              wx.onMenuShareTimeline({
                                  title:  that.title,   // 分享时的标题
                                  link: link,     // 分享时的链接
                                  desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                                  imgUrl: that.photopath,    // 分享时的图标
                                  success: function () {
                                      console.log("分享成功");
                                  },
                                  cancel: function () {
                                      console.log("取消分享");
                                  }
                              });
                              //分享给朋友
                              wx.onMenuShareAppMessage({
                                  title:  that.title,
                                  desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                                  link: link,
                                  imgUrl: that.photopath,
                                  type: '',
                                  dataUrl: '',
                                  success: function () {
                                      console.log("分享成功");
                                  },
                                  cancel: function () {
                                      console.log("取消分享");
                                  }
                              });
                          });
                          wx.error(function(res){
                              console.log(res);
                              // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                          })
                      }
                  })
                  .catch(err => {
                      console.log("error");
                      console.log(err);
                  })
          }
      },
      created() {
          console.log('测试');
          let groupId = this.$route.query.groupId
          if(groupId) {
              this.groupId = groupId;
          } else if(this.getGroupId()) {
              this.groupId = this.getGroupId();
          } else {
              this.groupId = 8;
          }
      },
      mounted() {
          this.getFreeAudition();
      },
      components: {},

  }
</script>
<style lang="less">

  @import '~@/assets/styles/common.less';

  .course {
      background-color: #ffffff;
      // overflow: hidden;
      padding: 0 0.8rem 8rem 0.8rem;
  }

  .popup-shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      // z-index: 99999;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, .5);
      z-index: 20;
  }

  // .slide-enter, .slide-leave-to {
  //   // 过渡之前的样式写在这里
  //    right: -100%;
  // }
  // .slide-enter-active,.slide-leave-active  {
  //   // 过渡属性写在这里
  //   transition: all .3s;
  // }
  // .slide-enter-to, .slide-leave {
  //   // 过渡之后的样式写在这里
  //    right: 0;
  // }
  .shop_page .van-sticky--fixed {
      position: fixed;
      top: 5.9rem;
      padding: 0 0.8rem 0.8rem 0.8rem;
      z-index: 9998;
  }

  .van-tab {
      padding: 0 1rem !important;
  }
  .projectClass {
      padding: 1.5rem 0;
  }
  .projectClass .activeP .van-grid-item__content {
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: #1989fa;
      color: #fff;
  }
  .projectClass .activeP .van-grid-item__content .van-grid-item__text {
      font-size: 2rem;
      color: #fff;
  }
  .projectClass .van-grid-item__content {
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: #f0f0f0;
  }
  .projectClass .van-grid-item__content .van-grid-item__text {
      font-size: 2rem;
  }
  .subjectShow .van-grid-item__content{
      background-color: #31BDEC;
  }
  .subjectShow .van-grid-item__content .van-grid-item__text{
      background-color: #31BDEC;
      color: white;
  }
</style>
