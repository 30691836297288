<!-- 
  author：
  page：提示  (取消  确认)
-->
<template>
  <div class="com_ok_cancel">
    <div class="com_ok_cancel_top">温馨提示</div>
    <div class="com_ok_cancel_title">{{ tip }}</div>
    <div class="com_ok_cancel_know">
      <div>
        <van-button type="warning" @click="Fun('0')" :disabled="submitDisabled">{{ tipLeft }}</van-button>
      </div>
      <div>
        <van-button
          type="info"
          @click="Fun('1')"
          :disabled="submitDisabled"
          >{{ tipRight }}</van-button
        >
      </div>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
import {testcollect, testPaperPracticeQuestionPost} from "@/request/api";
import {Toast} from "vant";

export default {
  components: {},
  props: ['tip', 'tipLeft', 'tipRight','testPaperId','examinationType'],
  data () {
    return {
      submitDisabled: false
    }
  },
  watch: {},
  created () { },
  methods: {
    // 点击（确定、取消）
    Fun (val) {
      console.log(val);
      // val  0：取消   1：确定
      // if(val == 1) {
      //   let questionList = this.$store.state.zxksList;
      //   let testPaperId = this.testPaperId;
      //   let examinationType = this.examinationType;
      //   const formData = []
      //   questionList.forEach(item => {
      //     if (item.type == 4) {
      //       if (item.answer !== '') {
      //         formData.push({
      //           id: item.id,
      //           answer: item.answer
      //         })
      //       }
      //     } else {
      //       if ((item.answer) ?? '' !== '') {
      //         formData.push({
      //           id: item.id,
      //           answer: item.answer
      //         })
      //       }
      //     }
      //   })
      //   console.log(formData);
      //   if(formData.length <= 0) {
      //     Toast.fail('请先作答后再提交');
      //     return false;
      //   }
      //   let that = this;
      //   testPaperPracticeQuestionPost({
      //     test_paper_id:testPaperId,
      //     question_time: '00:00:00',
      //     type: '4',
      //     examination_type: examinationType,
      //     question_data: formData
      //   })
      //           .then(res => {
      //             if (res.data.code == 1) {
      //               console.log(res.data);
      //               Toast.success('提交成功');
      //               let id = res.data.data.id;
      //               this.$emit('cancelTestSubmit', val,id) //调用上一级
      //             }
      //           })
      //           .catch(err => {
      //             console.log(err);
      //             Toast.fail('操作错误');
      //           })
      // } else {
        //暂不提交 如果考试模式 到时间不可答题
        this.$emit('cancelTestSubmit', val) //调用上一级
      // }
      // this.$emit('cancelTestSubmit', val)
    }
  }
}
</script>
 
<style scoped>
.com_ok_cancel {
  overflow-y: hidden;
}
.com_ok_cancel_top {
  height: 6rem;
  font-size: 2rem;
  line-height: 6rem;
  background: #1989fa;
  color: #ffffff;
  text-align: center;
}
.com_ok_cancel_title {
  height: 12rem;
  line-height: 3rem;
  font-weight: bold;
  font-size: 2rem;
  padding-top: 4rem;
  width: 80%;
  margin: 0 10% 0 10%;
}
.com_ok_cancel_know {
  height: 8rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  text-align: center;
  width: 100%;
}
.com_ok_cancel_know > div {
  width: 12rem;
  padding: 1rem;
}
.com_ok_cancel_know /deep/ .van-button {
  width: 100%;
}
</style>
