<template>
  <div class="comJudge">
    <div class="comJudge_div" v-if="type==='0'">
      <div class="comJudge_title">
        {{$store.state.currentIndex+1}}.
        <span style="margin-left:0.5rem">{{obj.title}}</span>
        <span class="comJudge_scoreClass">（{{obj.score}}分）</span>
      </div>
      <div class="comJudge_option">
      <div
        v-for="(item,index) in obj.tmList"
        :key="index"
        :class="item.state||item.id===obj.answer?'inputClassOk':'inputClass'"
        @click="clickHandle(item,index,'0')"
      >{{item.key}}</div>
      </div>
    </div>

    <!-- 考试结束预览试题 -->
    <div v-if="type==='1'" class="commentRadio">
      <div v-for="(item,index) in obj.tmList" :key="index" class="inputClass">
        <input
          :id="item.id"
          type="radio"
          :value="item.id"
          :disabled="true"
          :checked="item.id===radioVal?true:false"
          @change="changeFun(item,'1')"
        >
        <label :for="item.id"></label>
        <span>
          <label :for="item.id">{{item.key}}</label>
        </span>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['obj', 'type'],
  data() {
    return {
      // 选中的答案
      radioVal: ''
    }
  },
  watch: {
    obj: {
      handler(val) {
        //拿到后台返回的正确选项显示，单选默认选中
        this.radioVal = val.answer
        //  this.$emit('getRadioVal', { tmId: this.questionId, radioValue: val })
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  methods: {
    // 选中试题
    changeFun(val) {
      this.$store.state.zxksList[this.$store.state.currentIndex] = currentTest;
      this.radioVal = val.id
      val.state = !val.state
    }
  }
}
</script>
 
<style scoped>
.comJudge_div .comJudge_title {
  width: auto;
  text-align: left;
  padding: 0 1rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.comJudge_div .comJudge_scoreClass {
  color: #a0a0a0;
}
/* 处理单选样式 */
.comJudge_div .comJudge_option input {
  width: 2rem;
  height: 2rem;
}

.comJudge_div .comJudge_option {
  margin: 1rem;
}

.comJudge_div .inputClass {
  position: relative;
  height: auto;
  padding: 1rem;
  /* margin-left: 15px; */
  text-align: left;
  margin-top: 1.5rem;
  background: #f6f7f9;
  border: 0.1rem solid #f6f7f9;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comJudge_div .inputClassOk {
  position: relative;
  height: auto;
  padding: 1rem;
  text-align: left;
  margin-top: 1.5rem;
  background: #f6f7f9;
  border: 0.1rem solid #f6f7f9;
  color: #1989fa;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comJudge_div .inputClass input[type='radio'] {
  width: 20px;
  height: 20px;
  opacity: 0;
}

/* /另一种样式 （预览试题）*/
.commentRadio input {
  width: 4.5vw;
  height: 4.5vw;
}
.commentRadio .inputClass {
  position: relative;
  line-height: 6vw;
  margin-left: 15px;
  text-align: left;
}

.commentRadio .inputClass input[type='radio'] {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.commentRadio .inputClass > label {
  position: absolute;
  left: -2px;
  top: 9px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #999;
}

/*设置选中的input的样式*/
/* + 是兄弟选择器,获取选中后的label元素*/
.commentRadio .inputClass input:checked + label {
  background-color: #f24d4b;
  border: 1px solid #f24d4b;
}
.commentRadio .inputClass input:checked + label::after {
  position: absolute;
  content: '';
  width: 5px;
  height: 10px;
  top: 0;
  left: 4.1px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
}
</style>
