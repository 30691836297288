<template>
  <div class="com_know">
    <div class="com_know_top">温馨提示</div>
    <div class="com_know_title">{{tip}}</div>
    <div class="com_know_know">
      <!-- <div>
        <van-button type="warning" @click="Fun('0')" :disabled="submitDisabled">取消</van-button>
      </div> -->
      <div>
        <van-button
          type="info"
          @click="Fun('1')"
          :disabled="submitDisabled"
          >立即交卷</van-button>
      </div>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  components: {},
  props: ['tip'],
  data() {
    return {
      submitDisabled: false
    }
  },
  watch: {},
  created() {},
  methods: {
    // 点击（确定、取消）
    Fun (val) {
      // val  0：取消   1：确定
      this.$emit('okTestTip', val)
    }
  }
}
</script>
 
<style scoped>
.com_know {
  overflow-y: hidden;
  /* height: 35rem; */
}
.com_know_top {
  height: 6rem;
  line-height: 6rem;
  font-size: 2rem;
  background: #1989fa;
  color: #ffffff;
  text-align: center;
}
.com_know_title {
  height: 12rem;
  line-height: 3rem;
  font-weight: bold;
  font-size: 2rem;
  margin: 4rem 10% 4rem 10%;
}
.com_know_know {
  height: 8rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  text-align: center;
  width: 100%;
}
.com_know_know > div {
  width: 12rem;
  padding: 1rem;
}
.com_know_know /deep/ .van-button {
  width: 100%;
  border-radius: 1rem;
}
</style>