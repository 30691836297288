<template>
    <div>
        <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #title>
                我的笔记
            </template>
        </van-nav-bar>
        <div style="padding: 6rem 0 22rem 0;">
            <van-tabs v-model:active="active" class="questionList" color="#1989fa" swipeable>
                <div v-if="NoteList.length > 0">
                    <van-tab :title="item.cate_name" v-for="(item,index) in NoteList">
                        <div style="padding-top: 0.5rem;">
                            <div class="courseList1" v-for="(item2,index2) in item.data">
                                <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">笔记</van-tag>
                                <van-cell center class="answering">
                                    <template #icon>
                                        <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
                                    </template>
                                    <template #title>
                                        <span class="custom-title">{{item2.username ? item2.username : '无'}}</span><br>
                                        <van-tag type="primary">视频位置：{{item2.video_duration}}</van-tag>
                                    </template>
                                    <template #label>
                                        <span>{{item2.title}}</span>
                                    </template>
                                </van-cell>
                                <div class="reply_tag">
                                    <van-tag round color="#1989fa" text-color="#ffffff">{{item2.stage_title}}</van-tag>
                                    <span class="reply_text">{{item2.video_title}}</span>
                                </div>
                                <div class="noteEdit">
                                    <van-tag round color="#1989fa" text-color="#ffffff" @click="myNoteeditGo(index,index2)">
                                        <van-icon name="edit"/>
                                        修改
                                    </van-tag>
                                    <van-tag round color="#ffffff" text-color="#1989fa">
                                        <van-icon name="eye-o"/>
                                        {{item2.type}}
                                    </van-tag>
                                    <van-tag round color="#ffffff" text-color="#656466" @click="delNote(item2.id)">
                                        <van-icon name="delete-o"/>
                                        删除
                                    </van-tag>
                                </div>
                            </div>
                            <!-- <van-empty image-size="30rem" description="暂无答疑问题"/> -->
                        </div>
                    </van-tab>
                </div>
                <div v-else>
                    <van-empty image-size="30rem" description="暂无提交的笔记内容" />
                </div>
            </van-tabs>
        </div>
    </div>
</template>

<script>

    // import Tips from '@/components/Tips'
    import {
        GetCartListData,
        ChangeGoodChecked,
        StepperChangeData,
        DeleteGood,
        noteAll,
        noteList,
        noteDel
    } from '@/request/api'
    import {Toast} from "vant";

    export default {
        name: 'MyNote',
        metaInfo: {
            title: '我的笔记', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        data() {
            return {
                // active:'',
                NoteList: []
            }
        },

        methods: {
            noteDetail() {
                this.$router.push('/MyNoteDetail')
            },

            delNote(id) {
                this.$dialog.confirm({
                    title: '删除提示',
                    message: '确认删除笔记吗？',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                    noteDel({id}).then(res => {
                        if (res.data.status == 1) {
                            Toast.success('删除成功');
                            this.onNote();
                        }
                    })
                }).catch(() => {
                    console.log('点击了取消')
                })
            },
//修改
            myNoteeditGo(index,index2) {
                this.$router.push({
                    name:'MyNoteAdd',
                    params:{
                        id:this.NoteList[index]['data'][index2].id,
                        commodityId:this.NoteList[index]['data'][index2].commodityId,
                        courseId:this.NoteList[index]['data'][index2].courseId,
                        courseWareId:this.NoteList[index]['data'][index2].courseWareId,
                        title:this.NoteList[index]['data'][index2].title,
                        currentTime:0
                    },
                    query:{
                        id:this.NoteList[index]['data'][index2].id,
                        commodityId:this.NoteList[index]['data'][index2].commodityId,
                        courseId:this.NoteList[index]['data'][index2].courseId,
                        courseWareId:this.NoteList[index]['data'][index2].courseWareId,
                        title:this.NoteList[index]['data'][index2].title,
                        currentTime:0
                    }
                });
            },
            onBack() {
                this.$router.push('/learningList')
            },
            onNote() {
                let that = this;
                noteAll({}).then(res => {
                    if (res.data.status == 1) {
                        that.NoteList = res.data.data
                    }
                })
            },
        },
        created() {
            this.onNote();
        }
    }
</script>

<style lang="less">
    .questionList .courseList1 {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 1.5rem;
    }

    .questionList .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }

    .questionList .courseList1 .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseList1 .answering .custom-title {
        font-size: 1.8rem;
        padding-right: 1rem;
    }

    .questionList .courseList1 .answering .van-cell__title .van-tag--primary {
        font-size: 1.8rem !important;
        padding: 0.4rem 0.8rem !important;
        border-radius: 0.4rem;
    }

    .questionList .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }

    .questionList .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 2rem;
        padding-top: 0.8rem;
        line-height: 2.6rem;
    }

    .questionList .courseList1 .project_tag {
        position: absolute;
        right: 1rem;
        float: right;
        padding: 0.3rem 0.8rem !important;
        font-size: 1.4rem !important;
        z-index: 99999;
        border-top-right-radius: 1rem;
    }

    .questionList .courseList1 .reply_tag {
        background-color: #f0f0f0;
        border-radius: 3rem;
        padding-left: 0.3rem;
        height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
        margin: 0 auto;
        line-height: 3.2rem;
    }

    .questionList .courseList1 .reply_tag .van-tag {
        height: 2.8rem !important;
        // width: 3rem !important;
        border-radius: 3rem;
        padding: 0 0.8rem !important;
        font-size: 1.6rem !important;
    }

    .questionList .courseList1 .reply_tag .reply_text {
        padding-left: 0.4rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .questionList .courseList1 .noteEdit {
        padding: 1.5rem 1rem 0 1rem;
        // display: flex;
        // justify-content: end;
        display: flow-root;
    }

    .questionList .courseList1 .noteEdit .van-tag {
        height: 2.8rem !important;
        // width: 20% !important;
        margin-left: 1rem;
        border-radius: 3rem;
        padding: 0 1rem 0 0.8rem !important;
        font-size: 1.6rem !important;
        text-align: center;
        float: right;
    }

    .questionList .courseList1 .noteEdit .van-tag .van-icon {
        padding-right: 0.4rem;
    }

</style>