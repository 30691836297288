<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        学习计划
      </template>
    </van-nav-bar>
    <div class="questionList" style="padding: 6rem 0 22rem 0;">
          <div style="padding-top: 0.5rem;">
                <div class="courseData" @click="questionDetail">
                    <!-- <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag> -->
                    <van-cell center class="answering" is-link>
                        <template #icon>
                            <!-- <van-icon name="../assets/icons/doc.png" /> -->
                            <img class="icon-img" src="http://cdnoss.ksyt.com.cn/wxImages/assets/icons/database.png" alt />
                        </template>
                        <template #title>
                            <span class="custom-title">90天内快速学员基础班课程，熟练掌握知识点</span>
                        </template>
                        <template #label>
                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>
                        </template>
                    </van-cell>
                </div>
                <!-- <van-empty image-size="30rem" description="电子资料暂未更新"/> -->
          </div>
    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
export default {
  name: 'learningPlan',
  metaInfo: {
    title: '学习计划', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
    }
  },
  created() {

  },

  methods: {
    questionDetail() {
      // this.$router.push('/CourseQuestionDetail')
      window.open('http://cdnoss.ksyt.com.cn/wxPDF/zhongyi-kaodian.pdf', '_blank');
      // http://cdnoss.ksyt.com.cn/wxPDF/zhongyi-kaodian.pdf
    },

    onBack() {
      history.back();
      window.android.back();
    },
  },
}
</script>
 
<style lang = "less" >
    .questionList .courseData {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 0.5rem;
    }
    .questionList .courseData .van-cell {
        border-radius: 1rem !important;
        padding: 1.5rem 1.5rem 1.5rem .5rem;
    }
    .questionList .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseData .answering .custom-title{
        // font-size: 1.8rem;
        font-weight: 600;
        padding-right: 1rem;
        line-height: 3rem;
    }
    .questionList .courseData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .questionList .courseData .answering .van-cell__label {
        color: #646466;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .questionList .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 9;
      border-top-left-radius: 1rem;
    }
    .questionList .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .questionList .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .questionList .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .icon-img{
      width: 8rem;

    }

</style>
