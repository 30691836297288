<template>
    <div>
        <van-nav-bar class="cart-title" title="答疑详情" fixed left-arrow @click-left="onBack"/>
        <div class="questionDetail">
            <div class="courseList1">
                <van-form @submit="onRegister">
                    <van-cell center class="answering">
                        <template #title>
                            <van-tag type="primary">答疑</van-tag>
                            <span class="custom-title">{{title}}</span>
                        </template>
                    </van-cell>
                    <van-cell-group inset>
                        <van-field
                                v-model="message"
                                rows="5"
                                autosize
                                type="textarea"
                                maxlength="180"
                                placeholder="请输入答疑内容"
                                show-word-limit
                                name="content"
                                :rules="[{ required: true, message: '请输入答疑内容' }]"
                        />
                    </van-cell-group>
                    <div style="margin: 2rem;">
                        <van-button round block type="info" native-type="submit">
                            提交
                        </van-button>
                    </div>
                    <!-- <van-empty image-size="30rem" description="暂无答疑问题"/> -->
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>

    // import Tips from '@/components/Tips'
    import { repliedPost } from '@/request/api'
    import {Toast} from "vant";

    export default {
        name: 'MyNoteDetail',
        metaInfo: {
            title: '笔记详情', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        data() {
            return {
                // active:'',
                checked: '',
                commodityId: '',
                courseId: '',
                courseWareId: '',
                title: '',
                message: ''
            }
        },

        methods: {

            onBack() {
                let commodityId = this.commodityId;
                let courseId = this.courseId;
                let courseWareId = this.courseWareId;
                this.$router.push({
                    name:'MyCourseDetail',
                    params:{
                        id:courseId,
                        commodityId,
                        coursewareId:courseWareId
                    }
                })
            },
            onRegister(values) {
                let that = this;
                let content = values["content"];
                if (!content) {
                    Toast.fail('请输入答疑内容');
                    return false;
                }
                let commodityId = that.commodityId;
                let courseId = that.courseId;
                let courseWareId = that.courseWareId;
                repliedPost({commodityId,courseId,courseWareId,content}).then(res => {
                    if (res.data.status == 1) {
                        Toast.fail('请输入答疑内容');
                        that.$router.push({
                            name:'MyCourseDetail',
                            params:{
                                id:courseId,
                                commodityId,
                                coursewareId:courseWareId
                            }
                        })
                    }
                })
            },
        },
        created() {
            if(this.$route.params.commodityId) {
                this.commodityId = this.$route.params.commodityId;
            } else {
                this.commodityId = this.$route.query.commodityId;
            }
            if(this.$route.params.courseId) {
                this.courseId = this.$route.params.courseId;
            } else {
                this.courseId = this.$route.query.courseId;
            }
            if(this.$route.params.courseWareId) {
                this.courseWareId = this.$route.params.courseWareId;
            } else {
                this.courseWareId = this.$route.query.courseWareId;
            }
            if(this.$route.params.title) {
                this.title = this.$route.params.title;
            } else {
                this.title = this.$route.query.title;
            }
        },
    }
</script>

<style lang="less">
    .questionDetail {
        margin: 6rem 1rem 1rem 1rem;
        padding: 1rem 0;
    }

    .questionDetail .courseList1 {
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }

    .questionDetail .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }

    .questionDetail .courseList1 .van-cell::after {
        border-width: 0.2rem !important;
    }

    .questionDetail .courseList1 .answering .custom-title {
        font-size: 1.6rem;
        padding-right: 1rem;
        color: #646566;
    }

    .questionDetail .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }

    .questionDetail .courseList1 .answering .van-cell__title .van-tag--primary {
        border-radius: 0.4rem;
    }

    .questionDetail .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding-top: 0.5rem;
    }

    .questionDetail .courseList1 .project_tag {
        position: absolute;
        right: 1rem;
        float: right;
        padding: 0.3rem 0.5rem !important;
        font-size: 1.4rem !important;
        z-index: 99999;
        border-top-right-radius: 1rem;
    }

    .questionDetail .courseList1 .van-field__body textarea {
        min-height: 15rem;
        line-height: 3rem;
        font-size: 1.8rem;
    }

    .questionDetail .courseList1 .openNote .van-cell__title {
        font-size: 1.6rem;
    }

    .questionDetail .courseList1 .openNote .van-switch {
        background-color: #969799;
    }

    .questionDetail .courseList1 .openNote .van-switch--on {
        background-color: #1989fa;
    }

</style>